const introInfo = {
    name: 'Fabian Walocha',
    title: 'Research Fellow',
    location: 'Braunschweig, Germany',
    description: 'Welcome to my page!',
    image: 'ec.jpg',
}

//dynamic icon
const socialMediaInfo = [{
        name: 'linkedin',
        link: 'https://www.linkedin.com/in/fabian-walocha-790359144/',
        username: 'fabian-walocha',
        displayName: 'linkedin/fabian-walocha'
    },

    {
        name: 'github',
        link: 'https://github.com/FabianWalocha',
        username: 'FabianWalocha',
        displayName: 'github/FabianWalocha'
    },
    // {
    //     name: 'stack-overflow',
    //     link: 'http://localhost:8080',
    //     username: 'coskuncayemre',
    //     displayName:'stackoverflow.com'
    // },
    // {
    //     name: 'stack-overflow',
    //     link: 'http://localhost:8080',
    //     username: 'coskuncayemre',
    //     displayName:'stackoverflow.com'
    // },
]


const contactInfo = [
    {
        name: 'envelope',
        context: 'fabian@walocha.me',
        size: '20px',
        style: "font-size:20px;margin-right: 10px;",
        link: 'mailto:fabian@walocha.me'
    },
    {
        name: 'globe',
        context: 'Google Scholar',
        size: '20px',
        style: "font-size:20px;margin-right: 13px;",
        link: 'https://scholar.google.com/citations?view_op=list_works&hl=en&user=ewFCvJ4AAAAJ'
    },
    
    // {
    //     name: 'phone-square',
    //     context: '+49 531 338138',
    //     size: '23px',
    //     style: "font-size:23px;margin-right: 10px;",
    //     link: 'tel:+49 531 338138'
    // },
]

const summaryInfo = 
    "Hi there! My name is Fabian Walocha, I'm a research fellow and doctoral candidate in computer science at DLR Germany. My background is in Cognitive Science and Data Science, so I'm mostly working in Python and R. Most of my work nowadays revolves around research and prototyping for smart services and modules for future mobility, both for cars and for public transit."


//desc v-html 
const experinceInfo = [
    {
        workAt: 'DLR Germany',
        position: 'Research Fellow',
        duration: 'Sep 2020 – ongoing',
        description: 'Research & development of user-adaptive systems, wearable integration, AI assistance systems.',
        techs: ["Python", "Imotions", "PsychoPy", "Flask", "Qt"]
    },
    {
        workAt: 'Télécom Paris (Intern)',
        position: 'Research Assistant',
        duration: 'Mar 2020 - Aug 2020',
        description: 'Modeling taks and social group cohesion, research on multi modal behavioral data, social signal processing.',
        techs: ["Python"]
    },
    {
        workAt: 'DLR Germany',
        position: 'Research Assistant',
        duration: 'Aug 2019 - Jan 2020',
        description: 'Modeling driver activity and task related stress during non-driving related tasks.',
        techs: ["Python",  "R", "SQLite"]
    },
    {
        workAt: 'Universität Kassel',
        position: 'Research Assistant',
        duration: 'Apr 2018 - Sep 2018',
        description: 'Probabilistic forecasting for wind and solar facilities',
        techs: ["Python"]
    },
    {
        workAt: 'Donders Institute for Brain, Cognition and Behaviour (Intern)',
        position: 'Research Assistant',
        duration: 'Jan 2018 – Aug 2018',
        description: 'Modeling functional connectomes in fMRI',
        techs: ["Matlab"]
    }
]

const educationInfo = [
    {
        school: 'Université Jean Monnet',
        location: 'Saint-Étienne, France',
        title: 'MSc in Machine Learning and Data Mining',
        duration: '2018 - 2020',
        description: '<b>GPA:</b> 16.5/20.0',
    },
    {
        school: 'Universität Osnabrück',
        location: 'Osnabrück, Germany',
        title: 'BSc in Cognitive Science',
        duration: '2013 – 2017',
        description: '<b>GPA:</b> 1.6',
    },
]

const volunteerInfo = [
    // {
    //     organisation: 'The English Studio Language School',
    //     title: 'Student',
    //     duration: 'July 2018 - Sep 2018',
    //     description: 'London,UK',
    // },
    // {
    //     organisation: 'Hacettepe University,Social Life Organization',
    //     title: 'Organization Manager',
    //     duration: '2015 – 2021',
    //     description: '',
    // }, {
    //     organisation: 'Hacettepe University,Community of Contest',
    //     title: 'Organization Manager',
    //     duration: '2016 – 2018',
    //     description: '',
    // },
    // {
    //     organisation: 'Hacettepe University ACM',
    //     title: 'Member',
    //     duration: '2015 – 2020',
    //     description: '',
    // }
]


const certificateInfo = [
    // {
    //     organisation: 'Red Hat',
    //     title: 'Red Hat Certified System Administrator RH124',
    //     duration: 'Feb,2021',
    //     description: '',
    // },
    // {
    //     organisation: 'KOSGEB',
    //     title: 'Entrepreneurship Certificate',
    //     duration: 'Jun,2019',
    //     description: '',
    // },
]

const projectInfo = [{
    //     title: 'Project 1',
    //     meta: 'Propulsion Engineer',
    //     description: 'Responsibilities included: writing technical reports and other documentation, such as handbooks and bulletins, for use by engineering staff, management, and customers, analyzing project requests and proposals and engineering data to determine feasibility, predictability, cost, and production time of aerospace or aeronautical product.',
    //     images: [{
    //             thumbnail: 'http://placeholdit.imgix.net/~text?txtsize=33&txt=firstimage&w=150&h=150',
    //             original: 'http://placeholdit.imgix.net/~text?txtsize=33&txt=firstimage&w=900&h=900'
    //         },
    //         {
    //             thumbnail: 'http://placeholdit.imgix.net/~text?txtsize=33&txt=secondimage&w=150&h=150',
    //             original: 'http://placeholdit.imgix.net/~text?txtsize=33&txt=secondimage&w=900&h=900'
    //         },
    //         {
    //             thumbnail: 'http://placeholdit.imgix.net/~text?txtsize=33&txt=thirdImage&w=150&h=150',
    //             original: 'http://placeholdit.imgix.net/~text?txtsize=33&txt=thirdImage&w=900&h=900'
    //         }
    //     ]
    // },
    // {
    //     title: 'Project 1',
    //     meta: 'Propulsion Engineer',
    //     description: 'Responsibilities included: writing technical reports and other documentation, such as handbooks and bulletins, for use by engineering staff, management, and customers, analyzing project requests and proposals and engineering data to determine feasibility, predictability, cost, and production time of aerospace or aeronautical product.',
    //     images: [{
    //             thumbnail: 'http://placeholdit.imgix.net/~text?txtsize=33&txt=firstimage&w=150&h=150',
    //             original: 'http://placeholdit.imgix.net/~text?txtsize=33&txt=firstimage&w=900&h=900'
    //         },
    //         {
    //             thumbnail: 'http://placeholdit.imgix.net/~text?txtsize=33&txt=secondimage&w=150&h=150',
    //             original: 'http://placeholdit.imgix.net/~text?txtsize=33&txt=secondimage&w=900&h=900'
    //         },
    //         {
    //             thumbnail: 'http://placeholdit.imgix.net/~text?txtsize=33&txt=thirdImage&w=150&h=150',
    //             original: 'http://placeholdit.imgix.net/~text?txtsize=33&txt=thirdImage&w=900&h=900'
    //         }
    //     ]
    }
]


const skillInfo = [
    {
        name: 'Python',
        rate: 85,
    },


    {
        name: 'R',
        rate: 63,
    },

    {
        name: 'Matlab',
        rate: 53,
    }, 

    {
        name: 'Kotlin',
        rate: 30,
    },

    {
        name: 'C++',
        rate: 25,
    },
    
]

const otherSkillInfo = ['TensorFlow','Torch','Keras', 'Jax', 'Git', "Flux"]

const proSkillInfo = ['Strong problem solver','Effective communicator','Team player']

// 0,1,2,3,4,5
const languageInfo = [{
        name: 'German',
        rate: 5,
        level: 'Native',
    },
    {
        name: 'English',
        rate: 5,
        level: 'Advanced',
    }, 
    {
        name: 'French',
        rate: 3,
        level: 'Intermediate',
    }, 
    {
        name: 'Portuguese',
        rate: 3,
        level: 'Intermediate',
    }, 
]



const interestInfo = [
    {
        name: 'Rock Climbing',
        description: "and hiking in general",
    },
    {
        name: 'Board games',
        description: "and going to pub quizzes",
    },
    // {
    //     name: 'Entrepreneurship Ideas',
    //     description: "",
    // },
    // {
    //     name: 'Smart Home Ideas',
    //     description: "",
    // },
]

export default {
    introInfo: introInfo,
    socialMediaInfo: socialMediaInfo,
    experinceInfo: experinceInfo,
    educationInfo: educationInfo,
    // projectInfo: projectInfo,
    skillInfo: skillInfo,
    // certificateInfo: certificateInfo,
    // volunteerInfo: volunteerInfo,
    languageInfo: languageInfo,
    contactInfo: contactInfo,
    interestInfo: interestInfo,
    summaryInfo: summaryInfo,
    otherSkillInfo: otherSkillInfo,
    proSkillInfo: proSkillInfo
}